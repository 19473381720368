/**
 *
 * @param email string
 */
export const validateEmail = email => {
	const regex = /\S+@\S+\.\S+/
	return regex.test(email)
}

/**
 *
 * @param value string
 */
export function validateEmpty(value) {
	switch (typeof value) {
		case 'string':
			return value.trim() !== ''
		case 'number':
			return value > 0
		default:
			return false
	}
}

export function validateUkPostcode(value) {
	// regex from the simplified patterns in the long answer here:
	// https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
	const regex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/
	return regex.test(value)
}

export function validateDonation(value) {
	return validateEmpty(Number(value))
}
